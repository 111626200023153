.head-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;

  &.small-bottom {
    padding-bottom: 0.5rem;
  }

  .title-container {
    display: flex;
    align-items: center;
  }

  h2 {
    margin-left: 0.625rem;
  }

  .refresh-button-container {
    display: flex;
    margin-left: 0.8rem;

    &:hover {
      cursor: pointer;
    }
  }
}
