.dsc-header-challenge {
  position: relative;
  width: 100%;
  height: 11.5rem;
  background: linear-gradient(90deg, #00aaff 0%, #23d2b5 100%);

  .dsc-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill-opacity: 1;
    fill: white;
  }

  #dsc-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.dsc-container {
  margin: 2rem;
  justify-content: space-around;
  width: -webkit-fill-available;
}

.dsc-grid {
  display: flex;
  flex-direction: row;
  padding: 2rem 0 0 0;

  .container-left {
    flex-basis: 60%;
    .dsc-challenge-details {
      margin-right: 1rem;
    }
  }

  .container-right {
    flex-basis: 40%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    .dsc-challenge-leaderboard {
      margin-left: 1rem;
      flex-basis: 100%;
      justify-content: space-between;
    }
  }
}

.dsc-challenge-user-area {
  margin-top: 2rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  .box:last-child {
    margin-left: 2rem;
  }
}

.dsc-image-challenge {
  float: right;
  padding: 1rem;
  width: 40%;
}

.dsc-grid-details {
  display: flex;
  flex-direction: row;

  > * {
    &:nth-child(1) {
      width: calc(100% / 10 * 6);
      margin: 0 1rem 0 0;
    }

    &:nth-child(2) {
      width: calc(100% / 10 * 4);
      margin: 0 0 0 1rem;
    }
  }
}

.dsc-challenge-score,
.dsc-challenge-metrics,
.dsc-challenge-participation {
  align-items: center;
  justify-content: flex-start;
}

.placement-number {
  text-align: left;
}

.congretulation-card {
  display: flex;
  width: 21.125rem;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}
