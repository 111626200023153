.container-badge-status {
  display: flex;
  .refresh-button-container {
    display: flex;
    margin-left: 0.8rem;

    &:hover {
      cursor: pointer;
    }
  }
}
