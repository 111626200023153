.dsc-card {
  padding: 2rem 2rem 2rem 2rem;
}

.fixed-width {
  flex-basis: 25%;
}

.centered-card {
  justify-content: center;
  align-items: center;
}
