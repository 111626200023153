nav ul {
  align-items: center;
}

.app-header {
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
