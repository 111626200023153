/* Podium */
.podium {
  display: flex;
  flex-direction: column;

  .top-3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;

    .podium-step {
      background: #ebeef1;
      display: flex;
      padding-top: 0.5rem;
      width: 9.52081rem;
      flex-direction: column;
      flex-shrink: 0;

      color: var(--neutral-colors-gray-900, #232d35);
      text-align: center;
      font-size: 0.875rem;
      font-family: Lato;
      font-weight: 800;
      line-height: 1.25rem;
    }

    .dsc-star-container {
      position: relative;
    }

    .dsc-squad-title {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .dsc-big-number {
      position: absolute;
      top: 8%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 33px;
      height: 35px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 35px;
      text-align: center;
      color: #232d35;
    }
    .gold {
      order: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .podium-step {
        width: 9.5rem;
        height: 5rem;
        flex-shrink: 0;
      }
    }
    .silver {
      order: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .podium-step {
        width: 9.5rem;
        height: 3.3125rem;
        flex-shrink: 0;
      }
    }
    .bronze {
      order: 3;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .podium-step {
        width: 9.5rem;
        height: 2.25rem;
        flex-shrink: 0;
      }
    }

    .icon {
      display: block;
      margin: auto;
    }
  }
}
.scoreboard-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & > .scoreboard-position {
    font-weight: bold;
    flex-basis: 10%;
  }
  & > .scoreboard-name {
    align-items: left;
    flex-basis: 80%;
  }
  & > .scoreboard-score {
    font-weight: bold;
  }
}
.dsc-leaderboard-team {
  width: -webkit-fill-available;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
}
