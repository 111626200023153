@import '~@engie-group/fluid-design-system/lib/base.css';

.dsc-container {
  margin: 2rem;
  width: -webkit-fill-available;
}

.dsc-list-challenges {
  width: -webkit-fill-available;
  background-color: white;
  border-collapse: collapse;

  .dsc-row {
    border-bottom: 1pt solid var(--nj-color-base-neutral-200);
  }
  th {
    color: var(--neutral-colors-gray-900, #1f2c34);
    font-size: 0.75rem;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  td {
    color: var(--neutral-colors-gray-900, #1f2c34);

    /* Body/Deci Regular 14px */
    font-size: 0.875rem;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }
  tr {
    margin-bottom: 0.5rem;
    td,
    th {
      text-align: center;
      padding: 1.5rem 0 1.5rem 0;
    }
  }
}
