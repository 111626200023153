#form-file-upload {
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

.label-file-upload {
  border: 1px dashed #607a8c !important;
}

.upload-button {
  gap: 0.5rem;
  background-color: transparent;
  border: none;
  color: #007acd;
  text-decoration: underline;
  cursor: pointer;

  :hover {
    text-decoration: none;
  }
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.dragover,
.dragenter {
  background-color: var(--nj-color-palette-ultramarine-300);
}

.dragleave {
  background-color: var(--nj-color-background-primary);
}

.participation-spinner {
  margin-top: 4rem;
}

.validation-container,
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .validate-icon,
  .error_icon {
    margin: auto;
  }
}
