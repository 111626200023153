@import '~@engie-group/fluid-design-system/lib/base.css';

body {
  background: #eeeeee;
  margin: 0;
  padding: 0;
}

main {
  margin-top: 6rem;
  display: flex;
}

.page-container {
  display: flex;
  flex-direction: column;
}
.content-wrap {
  flex: 1;
}

.__text-center {
  text-align: center !important;
}

.__nj-card-body {
  height: 15rem;
}

.__nj-card {
  padding: 1rem;
  margin: 1rem;
}

.__nj-table {
  margin: -13px;
}

h1 {
  font-family: Clan Pro, Lato;
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 4.5rem;
  letter-spacing: 0px;
  text-align: center;
  color: #182663;
  margin: 0;
}

h2 {
  font-family: Lato;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
  letter-spacing: 0px;
  text-align: left;
  color: #171d21;
  margin: 0;
}

h3 {
  font-family: Lato;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.875rem;
  letter-spacing: 0px;
  text-align: center;
}

p {
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.p-24 {
  color: var(--neutral-grey-800, #314452);
  text-align: center;
  /* Body/Base Regular 16px | 24px */
  font-size: 1rem;
  font-family: Lato;
  line-height: 1.5rem;
}

.big-p {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.875rem;
}

.bold {
  color: var(--neutral-colors-gray-900, #232d35);
  text-align: center;
  font-size: 0.875rem;
  font-family: Lato;
  font-weight: 800;
  line-height: 1.25rem;
}
